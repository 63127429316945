<template>
  <!--begin::Card-->
  <v-row justify="center">
    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
      <v-overlay absolute :value="overlay"
        ><v-progress-circular indeterminate size="64"></v-progress-circular
      ></v-overlay>
      <div
        v-if="!currentUserRoles.includes(99) && !currentUser.email_verified_at"
        class="card card-custom"
      >
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h5 class="card-label font-weight-bolder text-dark">
              Kata laluan baharu
            </h5>
          </div>
          <div class="card-toolbar">
            <button
              type="submit"
              class="btn btn-success mr-2"
              @click="save()"
              ref="kt_save_changes"
            >
              Simpan
            </button>
            <button type="reset" class="btn btn-secondary" @click="cancel()">
              Batal
            </button>
          </div>
          <!-- <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span> -->
        </div>
        <v-card-text>
          <v-overlay absolute :value="true">
            <h6 class="pa-4 text-center">
              Sila setkan dan sahkan e-mel terlebih dahulu
            </h6>
          </v-overlay>
          <form class="form" id="kt_password_change_form">
            <div class="card-body">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-alert"
                  >Kata laluan baharu</label
                >
                <div class="col-lg-9 col-xl-6">
                  <input
                    type="password"
                    class="form-control form-control-lg form-control-solid"
                    value=""
                    placeholder="Kata laluan baharu"
                    name="new_password"
                    ref="new_password"
                    minlength="6"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >Pengesahan kata laluan</label
                >
                <div class="col-lg-9 col-xl-6">
                  <input
                    type="password"
                    class="form-control form-control-lg form-control-solid"
                    value=""
                    placeholder="Pengesahan kata laluan"
                    name="verify_password"
                    ref="verify_password"
                  />
                </div>
              </div>
            </div>
          </form>
        </v-card-text>
      </div>
      <div v-else class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
          <div
            v-if="currentUser.pwd_enabled"
            class="card-title align-items-start flex-column"
          >
            <h3 class="card-label font-weight-bolder text-dark">
              Tukar kata laluan
            </h3>
            <!-- <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span> -->
          </div>
          <div v-else class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">
              Kata laluan baharu
            </h3>
            <!-- <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span> -->
          </div>
          <div class="card-toolbar">
            <button
              type="submit"
              class="btn btn-success mr-2"
              @click="save()"
              ref="kt_save_changes"
            >
              Simpan
            </button>
            <button type="reset" class="btn btn-secondary" @click="cancel()">
              Batal
            </button>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form" id="kt_password_change_form">
          <div class="card-body">
            <div v-if="currentUser.pwd_enabled" class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-alert"
                >Kata laluan semasa</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  type="password"
                  class="form-control form-control-lg form-control-solid mb-2"
                  value=""
                  placeholder="Kata laluan semasa"
                  name="current_password"
                  ref="current_password"
                  minlength="6"
                />
                <a
                  href="#"
                  @click="forgotPassword()"
                  class="text-sm font-weight-bold"
                  >Lupa kata laluan ?</a
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-alert"
                >Kata laluan baharu</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  type="password"
                  class="form-control form-control-lg form-control-solid"
                  value=""
                  placeholder="Kata laluan baharu"
                  name="new_password"
                  ref="new_password"
                  minlength="6"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label"
                >Pengesahan kata laluan</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  type="password"
                  class="form-control form-control-lg form-control-solid"
                  value=""
                  placeholder="Pengesahan kata laluan"
                  name="verify_password"
                  ref="verify_password"
                />
              </div>
            </div>
          </div>
        </form>
        <!--end::Form-->
      </div>
      <InfoMsgDialog
        v-model="openInfoMsgDialog"
        :title="'Semak e-mel anda'"
        :content="
          'Pesanan telah dihantar ke e-emel ' +
          currentUser.email +
          '. Sila semak e-mel anda untuk teruskan proses set semula kata laluan anda.'
        "
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
        proceedButton="OK"
      ></InfoMsgDialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { CREATE_PASSWORD } from "@/core/services/store/user.module";
import { REQUEST_RESET_PASSWORD } from "@/core/services/store/notification.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_USER } from "@/core/services/store/user.module";

import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

// import InfoMsgDialog from "@/view/pages/widgets/MessageDialog.vue";

export default {
  name: "ChangePassword",
  components: {
    // InfoMsgDialog,
  },
  data() {
    return {
      overlay: false,
      password: "",
      status: "",
      valid: true,
      openInfoMsgDialog: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserRoles"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      {
        title:
          this.currentUser.pwd_enabled == true
            ? "Change password"
            : "Kata laluan baharu",
        route: "/change-password",
      },
    ]);

    const password_change_form = KTUtil.getById("kt_password_change_form");
    var curr_password = this.currentUser.password;

    this.fv = formValidation(password_change_form, {
      fields: {
        current_password: {
          validators: {
            notEmpty: {
              message: "Current password is required",
            },
            identical: {
              compare: function () {
                return curr_password;
              },
              message: "Wrong password",
            },
          },
        },
        new_password: {
          validators: {
            notEmpty: {
              message: "New password is required",
            },
          },
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return password_change_form.querySelector(
                  '[name="new_password"]'
                ).value;
              },
              message: "The password and its confirm are not the same",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton(),
      },
    });
  },
  methods: {
    async save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var password = this.$refs.new_password.value;
        var verify_password = this.$refs.verify_password.value;
        const submitButton = this.$refs["kt_save_changes"];

        // set spinner to submit button
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(async () => {
          // send update request
          await this.$store
            .dispatch(CREATE_PASSWORD, {
              user_id: this.currentUser.id,
              password: password,
              c_password: verify_password,
            })
            // go to which page after successfully update
            .then((response) => {
              if (response.data.success == true) {
                submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
                );
                this.$router.push({ name: "Dashboard" });
              }
            })
            .catch((err) => {
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      // this.fv.on("core.form.invalid", () => {
      //   Swal.fire({
      //     title: "",
      //     text: "Please, provide correct data!",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary"
      //   });
      // });
    },
    cancel() {
      // this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },
    async forgotPassword() {
      this.overlay = true;
      await this.$store
        .dispatch(REQUEST_RESET_PASSWORD, {
          email: this.currentUser.email,
          from: this.$route.path,
          next: "/dashboard",
        })
        .then((response) => {
          if (response.data.success == true) {
            this.overlay = false;
            this.openInfoMsgDialog = true;
          }
        });
    },
  },
};
</script>
