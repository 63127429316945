var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
        [
          _c(
            "v-overlay",
            { attrs: { absolute: "", value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          !_vm.currentUserRoles.includes(99) &&
          !_vm.currentUser.email_verified_at
            ? _c(
                "div",
                { staticClass: "card card-custom" },
                [
                  _c("div", { staticClass: "card-header py-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card-title align-items-start flex-column",
                      },
                      [
                        _c(
                          "h5",
                          {
                            staticClass:
                              "card-label font-weight-bolder text-dark",
                          },
                          [_vm._v(" Kata laluan baharu ")]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "card-toolbar" }, [
                      _c(
                        "button",
                        {
                          ref: "kt_save_changes",
                          staticClass: "btn btn-success mr-2",
                          attrs: { type: "submit" },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v(" Simpan ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "reset" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_vm._v(" Batal ")]
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-overlay",
                        { attrs: { absolute: "", value: true } },
                        [
                          _c("h6", { staticClass: "pa-4 text-center" }, [
                            _vm._v(
                              " Sila setkan dan sahkan e-mel terlebih dahulu "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          attrs: { id: "kt_password_change_form" },
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "col-xl-3 col-lg-3 col-form-label text-alert",
                                },
                                [_vm._v("Kata laluan baharu")]
                              ),
                              _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                                _c("input", {
                                  ref: "new_password",
                                  staticClass:
                                    "form-control form-control-lg form-control-solid",
                                  attrs: {
                                    type: "password",
                                    value: "",
                                    placeholder: "Kata laluan baharu",
                                    name: "new_password",
                                    minlength: "6",
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "col-xl-3 col-lg-3 col-form-label",
                                },
                                [_vm._v("Pengesahan kata laluan")]
                              ),
                              _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                                _c("input", {
                                  ref: "verify_password",
                                  staticClass:
                                    "form-control form-control-lg form-control-solid",
                                  attrs: {
                                    type: "password",
                                    value: "",
                                    placeholder: "Pengesahan kata laluan",
                                    name: "verify_password",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "card card-custom" }, [
                _c("div", { staticClass: "card-header py-3" }, [
                  _vm.currentUser.pwd_enabled
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "card-title align-items-start flex-column",
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "card-label font-weight-bolder text-dark",
                            },
                            [_vm._v(" Tukar kata laluan ")]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "card-title align-items-start flex-column",
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "card-label font-weight-bolder text-dark",
                            },
                            [_vm._v(" Kata laluan baharu ")]
                          ),
                        ]
                      ),
                  _c("div", { staticClass: "card-toolbar" }, [
                    _c(
                      "button",
                      {
                        ref: "kt_save_changes",
                        staticClass: "btn btn-success mr-2",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v(" Simpan ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "reset" },
                        on: {
                          click: function ($event) {
                            return _vm.cancel()
                          },
                        },
                      },
                      [_vm._v(" Batal ")]
                    ),
                  ]),
                ]),
                _c(
                  "form",
                  {
                    staticClass: "form",
                    attrs: { id: "kt_password_change_form" },
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _vm.currentUser.pwd_enabled
                        ? _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-xl-3 col-lg-3 col-form-label text-alert",
                              },
                              [_vm._v("Kata laluan semasa")]
                            ),
                            _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                              _c("input", {
                                ref: "current_password",
                                staticClass:
                                  "form-control form-control-lg form-control-solid mb-2",
                                attrs: {
                                  type: "password",
                                  value: "",
                                  placeholder: "Kata laluan semasa",
                                  name: "current_password",
                                  minlength: "6",
                                },
                              }),
                              _c(
                                "a",
                                {
                                  staticClass: "text-sm font-weight-bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.forgotPassword()
                                    },
                                  },
                                },
                                [_vm._v("Lupa kata laluan ?")]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "col-xl-3 col-lg-3 col-form-label text-alert",
                          },
                          [_vm._v("Kata laluan baharu")]
                        ),
                        _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                          _c("input", {
                            ref: "new_password",
                            staticClass:
                              "form-control form-control-lg form-control-solid",
                            attrs: {
                              type: "password",
                              value: "",
                              placeholder: "Kata laluan baharu",
                              name: "new_password",
                              minlength: "6",
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-xl-3 col-lg-3 col-form-label" },
                          [_vm._v("Pengesahan kata laluan")]
                        ),
                        _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                          _c("input", {
                            ref: "verify_password",
                            staticClass:
                              "form-control form-control-lg form-control-solid",
                            attrs: {
                              type: "password",
                              value: "",
                              placeholder: "Pengesahan kata laluan",
                              name: "verify_password",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
          _c("InfoMsgDialog", {
            attrs: {
              title: "Semak e-mel anda",
              content:
                "Pesanan telah dihantar ke e-emel " +
                _vm.currentUser.email +
                ". Sila semak e-mel anda untuk teruskan proses set semula kata laluan anda.",
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
              proceedButton: "OK",
            },
            model: {
              value: _vm.openInfoMsgDialog,
              callback: function ($$v) {
                _vm.openInfoMsgDialog = $$v
              },
              expression: "openInfoMsgDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }